import { authHeader } from "../functions/Utility";

const API_URL = process.env.REACT_APP_API_URL;
const axios = require("axios");

export async function getAllUsers() {
  let url = `${API_URL}/users`;
  const response = await axios.get(url, authHeader());
  return response.data;
}

export async function getUserByDept(DeptNo) {
  let deptNo = encodeURIComponent(DeptNo);
  let url = `${API_URL}/users/DeleteInd/No/Department/${deptNo}`;
  const response = await axios.get(url, authHeader());
  return response.data;
}

export const AddNewUser = async (User) => {
  let url = `${API_URL}/users`;
  const response = await axios.post(url, User, authHeader());
  return response.data;
};

export const UpdateUser = async (UserId, User) => {
  let userId = encodeURIComponent(UserId);
  let url = `${API_URL}/users/UserId/${userId}`;
  const response = await axios.post(url, User, authHeader());
  return response.data;
};

export const DeleteUser = async (UserId, User) => {
  let userId = encodeURIComponent(UserId);
  let url = `${API_URL}/users/delete/UserId/${userId}`;
  const response = await axios.post(url, User, authHeader());
  return response.data;
};

//localAuthenticate
export async function localAuthenticate(UserName) {
  let userName = encodeURIComponent(UserName);
  let url = `${API_URL}/users/authenticate/email/${userName}`;
  const response = await axios.get(url);
  return response.data;
}

//AD auth
export async function authenticate(Email, accessToken) {
  let email = encodeURIComponent(Email);
  let options = { headers: { Authorization: `Bearer ${accessToken}` } };
  let userUrl = `${API_URL}/users/authenticate/email/${email}`;
  const response = await axios.get(userUrl, options);
  return response.data;
}
