import { useMsal } from "@azure/msal-react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { loginRequest } from "../authConfig";
import LoginImage from "../images/LoginImage.png";
import Logo from "../images/Godrej_Precision_Logo.svg";

export default function Login({ validUser }) {
  const { instance } = useMsal();
  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <Container className="login-container">
      <Row className="login-width">
        <Col md={8} className="login-container-left">
          <Image src={LoginImage} alt="Login_Image" />
        </Col>
        <Col
          md={4}
          className="d-flex flex-column justify-content-center min-vh-100 login-container-right"
        >
          <div>
            <Image src={Logo} alt="Logo" />
          </div>
          <h2 className="app-name">
            Online Design
            <br />
            Release System
            <br />
          </h2>

          <div
            className="login-btn pink-btn"
            onClick={() => handleLogin(instance)}
          >
            <span>Login</span>
          </div>
          {/* <div className="brand mt-auto">
            Ideated By Godrej Construction
            <br /> Designed And Developed By Godrej Infotech
          </div> */}
        </Col>
      </Row>
    </Container>
  );
}
